import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/Seo';
import ThreeColCta from '../../../../components/ThreeColCta';

const HorizonApp = () => (
	<Layout>
		<Seo
			title="Download Sony hearing aids App | hear.com"
			description="Dowload the Sony hearing aids app by clicking here. Stay always connected with Sony hearing aids app."
		/>
		<div className="o-wrapper">
			<div className="o-row-2p5">
				<div className="c-newsletter-img">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png"
						alt="man sitting on couch and smiling"
						loading="lazy"
					/>
				</div>
			</div>
			<div className="o-row-2p5">
				<h1 className="c-content-hero__headline">Download Sony Hearing Aids App</h1>
			</div>
			<ul className="c-usps">
				<li>Easy to set up and kind instructions </li>
				<li>Personalize to your hearing profile </li>
				<li>Adjust loudness according to your perception</li>
			</ul>
			<div className="c-app-box">
				<a
					className="c-app-box__panel c-app-box__android"
					href="https://play.google.com/store/apps/details?id=jp.co.sony.nb.hearingcontrol&hl=en_US"
				>
					<p className="c-app-box__android-title">Android</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/android-small.png"
						alt=""
					/>
				</a>
				<a className="c-app-box__panel c-app-box__ios" href="https://apps.apple.com/us/app/sony-hearing-control/id1633950748">
					<p className="c-app-box__ios-title">iOS</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/ios-small.png"
						alt=""
					/>
				</a>
			</div>
			<section className="c-app-section">
				<div className="o-row-2p5">
					<p className="c-app-section__title">Sony app</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
                    This app is for Sony self-fitting hearing aids, CRE-C10 and CRE-E10. Easy and quick initial setup and remote control with kind instructions. 
                    Personalized to your hearing: The hearing aid can easily be customized to your hearing through the self-fitting test on the Sony | Hearing control app, so you don’t miss out on a thing.It is easily controlled with your smartphone: Easy to set up yourself, control volume, sound balance (tone), and directionality* with the app. The device communicates to your smartphone by acoustic link and Bluetooth*.

					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
                    <strong>Exeptions:</strong>
                    &nbsp;
                    &nbsp;
                    *CRE-C10 Bluetooth connectivity for audio streaming* / *iPhone with Apple iOS (13 or higher)*
					</p>
				</div>
			</section>
			<div className="o-row-2p5">
				<p className="c-app-section__title">Resources</p>
			</div>
			<div className="o-row-2p5">
				<ThreeColCta
					ImgSrc1="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
					ContentTitle1="Hearing Test"
					ContentCopy1="Check how is your hearing in less than 5 minutes."
					Url1="/hearing-test/"
					ImgSrc2="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg"
					ContentTitle2="Hearing Loss"
					ContentCopy2="There are several types of hearing loss and many different cause."
					Url2="/hearing-loss/"
					ImgSrc3="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg"
					ContentTitle3="Hearing Aids"
					ContentCopy3="Connect you to the world around you with our wide range of devices."
					Url3="/hearing-aids/"
				/>
			</div>
		</div>
	</Layout>
);

export default HorizonApp;
